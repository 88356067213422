import { render, staticRenderFns } from "./RegionsSelectComponent.vue?vue&type=template&id=7a5a7dc0&"
import script from "./RegionsSelectComponent.vue?vue&type=script&lang=ts&"
export * from "./RegionsSelectComponent.vue?vue&type=script&lang=ts&"
import style0 from "./RegionsSelectComponent.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VProgressCircular,VSelect})
